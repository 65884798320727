import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ReplyInput.module.css';
import { Input } from '@mui/material';
// import file from '../../img/file.png';
import camera from '../../img/camera.png';
import send from '../../img/send.png';
import useMutate from '../../hooks/useMutate';
import Media from '../Media';
import axios from 'axios';
import UnlockableUpload from '../UnlockableUpload';
import CustomButton from '../CustomButton';

const ReplyInput = ({
  value,
  onChange,
  onSend,
  onFilesUploaded,
  onFilesRemoved,
  focus,
  onError,
  placeholder = 'Write a message...',
  isCreatorView,
  recipient,
  payToSend,
  sending,
}) => {
  const [files, setFiles] = useState([]);
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  const fileInputRef = React.useRef();

  const [uploadMedia] = useMutate('/media');

  const handleSend = async () => {
    await onSend();
    setFiles([]);
  }

  const handleUnlockableSubmit = async (values) => {
    const newFiles = Array.from(values.files);

    const { data } = await uploadMedia({
      isCreator: isCreatorView,
      files: newFiles.map(file => ({
        type: file.type,
        uploadType: 'UNLOCKABLE',
        title: values.title,
        price: values.price
      }))
    });

    await Promise.all(newFiles.map((file, i) => {
      return axios.put(data.files[i].url, file, {
        headers: { 'Content-Type': file.type },
      });
    }))

    setFiles(files.concat(newFiles.map(file => ({
      file,
      uploadType: 'UNLOCKABLE',
      title: values.title,
      price: values.price
    }))));
    onFilesUploaded(data.files);
    setMediaDialogOpen(false);
  }

  const handleFilesAdded = async (evt) => {
    const newFiles = Array.from(evt.target.files);
    
    const { data } = await uploadMedia({
      isCreator: isCreatorView,
      files: newFiles.map(file => ({
        type: file.type,
        uploadType: 'MESSAGE',
        recipientId: recipient?.id
      }))
    });

    await Promise.all(newFiles.map((file, i) => {
      return axios.put(data.files[i].url, file, {
        headers: { 'Content-Type': file.type },
      });
    }))

    setFiles(files.concat(newFiles.map(file => ({
      file,
      uploadType: 'MESSAGE'
    }))));
    onFilesUploaded(data.files);
  }

  const handleFilesRemoved = () => {
    setFiles([]);
    onFilesRemoved();
  }

  return (
    <>
      <div className={ styles.replyContainer }>
        <input
          ref={fileInputRef}
          className={ styles.hidden }
          type="file"
          onChange={handleFilesAdded}
          accept=".jpg,.gif,.png,.svg,.mp4,.mpeg,.avi"
        />
        <img 
          className={ styles.camera }
          src={ camera }
          onClick={ () => fileInputRef.current.click() }
          alt='Upload'
        />
        {/* { isCreatorView && <img
          alt='Create unlockable'
          className={ styles.file }
          src={ file }
          onClick={ () => setMediaDialogOpen(true) }
          width={ 19 }
          height={ 21 }
        /> } */}
        <div className={ styles.reply }>
          { files.length > 0 && <div
            className={ styles.filePreview }
          >
          { files.map(({ uploadType, file, title, price }, i) => (
            <Media
              className={ styles.sendMediaPreview }
              key={ `file-preview-${ i }` }
              type={ uploadType }
              title={ title }
              price={ price }
              file={ file }
              icon={ <div 
                className={ styles.removeImage }
                onClick={ handleFilesRemoved }>X</div> }
            />
          )) }
          </div> }
          <Input
            className={ files.length > 0 ? styles.replyInputWithImage : styles.replyInput }
            value={ value }
            autoFocus={ focus }
            disableUnderline
            placeholder={ placeholder }
            multiline
            fullWidth
            onChange={ onChange }
            inputProps={ isCreatorView ? {} : { maxLength: 250 }}
            endAdornment={
              <>
                { isCreatorView
                  ? <CustomButton 
                      onSubmit={ handleSend }
                      loading={ sending }
                      fullWidth={ false }
                      className={ styles.sendButton }
                    >
                      { sending 
                        ? <div className={ styles.sendIconSolo } /> 
                        : <img 
                            className={styles.sendIcon} 
                            src={ send } 
                            alt='Send' 
                            width={ 28 } 
                            height={ 28 } 
                          />
                      }
                    </CustomButton>
                  : <CustomButton 
                      onSubmit={ handleSend }
                      loading={ sending }
                      fullWidth={ false }
                      className={ styles.sendButtonFan }
                      endIcon={ 
                        <img 
                          className={styles.sendIcon} 
                          src={ send } 
                          alt='Send' 
                          width={ 20 } 
                          height={ 22 } 
                        /> 
                      }
                    >
                      { payToSend ? `$${recipient?.dmPrice}` : 'Free' }
                    </CustomButton> }
              </>
            }
          />
        </div>
      </div>
      <UnlockableUpload 
        isOpen={ mediaDialogOpen } 
        onClose= { () => setMediaDialogOpen(false) } 
        onError={ onError }
        files={ files }
        onSubmit={ handleUnlockableSubmit }
      />
    </>
  )
}

ReplyInput.propTypes = {
  sending: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSend: PropTypes.func,
  onFilesAdded: PropTypes.func,
  onFilesUploaded: PropTypes.func,
  onFilesRemoved: PropTypes.func,
  onError: PropTypes.func,
  placeholder: PropTypes.string,
  isCreatorView: PropTypes.bool,
  payToSend: PropTypes.bool,
  recipient: PropTypes.shape({
    id: PropTypes.string,
    dmPrice: PropTypes.number
  })
}

export default ReplyInput;