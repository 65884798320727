import React, { useCallback, useContext, useState } from "react";
import PropTypes from 'prop-types';
import { Avatar, Badge } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { addDays, isFuture, differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns';

import './index.css';
import styles from './ChatPreview.module.css';
import useChat from "../../../hooks/useChat";
import { UserContext } from "../../../context";
import useMutate from "../../../hooks/useMutate";
import { useCreatorAnalytics } from "../../../hooks/useAnalytics";
import ReplyInput from "../../ReplyInput";
import CoinAnimation from "../../CoinAnimation";

const timeDiff = (laterDate, earlierDate) => {
  // get days, use if greater than zero
  const diffInDays = differenceInDays(laterDate, earlierDate);
  if(diffInDays > 0) return `${ diffInDays }d`;
  // get hours, use if greater than zero
  const diffInHours = differenceInHours(laterDate, earlierDate);
  if(diffInHours) return `${ diffInHours }h`;
  // get minutes
  const diffInMins = differenceInMinutes(laterDate, earlierDate);
  if(diffInMins) return `${ diffInMins }m`;

  const diffInSeconds = differenceInSeconds(laterDate, earlierDate);
  if(diffInSeconds) return `${ diffInSeconds }s`;
}

const formatRespondTime = (date) => {
  // get date seven days from now
  const sevenDaysFromDate = addDays(date, 7);
  // is this date in the future
  if(isFuture(sevenDaysFromDate)) return timeDiff(sevenDaysFromDate, new Date());
  return '0d';
}

const ChatPreview = ({ 
  chatId, 
  recipient = {}, 
  needsReply,
  unrepliedCount,
  refetchConversations, 
  refetchBalance, 
  onError, 
  bankLocation
}) => {
  const { user } = useContext(UserContext);
  const [{ messages, participants }, { sendMessage }] = useChat(chatId, 2);
  const [media, setMedia] = useState([]);
  const [text, setText] = useState('');
  const [showCoins, setShowCoins] = useState(false);
  const [sending, setSending] = useState(false);
  const [fulfillOrders] = useMutate('/order/fulfillment');
  const sendCreatorEvent = useCreatorAnalytics();
  const navigate = useNavigate();

  const handleChange = useCallback((event) => {
    setText(event.target.value);
  }, []);

  const handleSend = useCallback(async () => {
    if(sending) return;
    setSending(true);

    if((messages.length > 1 || !user?.creator?.firstMessageFree) && needsReply) {
      const { error } = await fulfillOrders({ 
        fanId: recipient.id,
        type: 'MESSAGE'
      });
      if(error) {
        onError('Unable to send message. Please try again.');
        return;
      }
    }

    let messageAttributes = {
      type: 'MESSAGE'
    };
    if(media.length > 0) {
      messageAttributes.media = media.map(file => file.id);
    }
    
    await sendMessage(text, messageAttributes);
    sendCreatorEvent({ action: 'quick reply message sent' })

    setText('');
    setMedia([]);
    setSending(false);

    if((messages.length > 1 || !user?.creator?.firstMessageFree) && needsReply) {
      setShowCoins(true);
      refetchBalance();
      setTimeout(() => {
        setShowCoins(false);
        refetchConversations();
      }, 6800);
    } else {
      refetchConversations();
    }

  }, [
    refetchBalance,
    refetchConversations,
    fulfillOrders,
    media,
    onError,
    recipient.id,
    sendCreatorEvent,
    sendMessage,
    text,
    sending,
    messages,
    needsReply,
    user?.creator
  ])

  const handleOpenFullChat = useCallback(() => {
    sendCreatorEvent({ action: 'open full chat' });
    navigate(`/creator/chat/${chatId}`);
  }, [chatId, navigate, sendCreatorEvent]);

  const respondTime = formatRespondTime(messages[messages.length - (unrepliedCount || 1)]?.dateCreated);
  return (
    <div className='chat-preview'>
      <div className='chat-preview-header'>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <div className={styles.online} />
          }
          invisible={ !participants[recipient?.id]?.isOnline }
        >
          <Avatar
            alt='User Avatar'
            src={ recipient.avatarUrl }
          />
        </Badge>
        <div className='chat-preview-title'>
          <p className='chat-preview-name'>
            { recipient?.firstName 
              ? `${recipient?.firstName} ${ recipient?.lastName }` 
              : recipient?.username
            }
          </p>
          { messages && messages.length > 0 && (
            <p className='chat-preview-subtitle'>{ 
              needsReply
              ?  messages?.length > 0 
                  ? `${ respondTime } left to respond`
                  : `Sent ${timeDiff(new Date(), messages[messages.length - 1]?.dateCreated)} ago`
              : `Replied ${ timeDiff(new Date(), messages[messages.length - 1]?.dateCreated) || '0s' } ago`
            }</p> 
          ) }
        </div>
        { needsReply && (messages.length > 1 || !user?.creator?.firstMessageFree) && respondTime !== '0d' && (
          <span className='chat-preview-payout'>
            Earn 🤑
          </span> 
        )}
        { !needsReply && <img 
          className='chat-preview-open-chat' 
          src='https://res.cloudinary.com/creator-tools/image/upload/w_100/v1649719884/static/Vector_9_vfszdr_zhwsad.png'
          alt='open chat'
          width={ 14 } 
          height={ 23 }
          onClick={ handleOpenFullChat }
        /> }
      </div>
      { needsReply && <div className='chat-preview-message' onClick={ handleOpenFullChat }>
        { messages && messages.length > 0 && (
          <div className='chat-preview-text'>
            <p>{ 
              messages[messages.length - 1]?.body || '[Media]'
            }</p> 
            { (unrepliedCount - 1) > 0 && (
              <p className='chat-preview-subtitle'>+{unrepliedCount - 1} more messages</p>
            )}
          </div>
        ) }
        <img 
          className='chat-preview-open-chat' 
          src='https://res.cloudinary.com/creator-tools/image/upload/w_100/v1649719884/static/Vector_9_vfszdr_zhwsad.png'
          alt='open chat'
          width={ 14 }
          height={ 23 }
        />
      </div> }
      { needsReply && messages.length > 1 && (
        <CoinAnimation 
          start={ showCoins } 
          destination={ bankLocation }
          className={ styles.coins }
        />
      ) }
      { needsReply && showCoins && (
        <div 
          className={ styles.earnedMoney }
        >
          { `You earned $$$` }
        </div>
      ) }

      { !showCoins && needsReply && <ReplyInput
        isCreatorView={ true }
        onFilesUploaded={ setMedia }
        onFilesRemoved={ () => setMedia([]) }
        value={ text }
        onChange={ handleChange }
        payToSend={ false }
        earn={ needsReply && messages.length > 1 }
        recipient={ recipient }
        onSend={ handleSend }
        onError={ onError }
        sending={ sending }
      /> }
    </div>
  )
}

ChatPreview.propTypes = {
  chatId: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatarUrl: PropTypes.string,
  }),
  recipient: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatarUrl: PropTypes.string,
  }),
  onSend: PropTypes.func,
  onError: PropTypes.func,
  needsReply: PropTypes.bool,
}

export default ChatPreview;