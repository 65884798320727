import React from 'react';
import { Avatar, Skeleton, Badge } from '@mui/material';
import PropTypes from 'prop-types';
import './index.css';
import styles from './Header.module.css';
import BackButton from '../../BackButton';

const Header = ({ firstName, lastName, avatarUrl, isOnline, loading, backButton, onBack }) => {
  return (
    <div>
      { loading ? (
        <Skeleton variant="rectangular" height={50} />
      ) : (
        <div className='header'>
          <div className={ styles.headerContainer }>          
          { backButton && <BackButton onClick={onBack} /> }
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <div className={styles.online} />
            }
            invisible={ !isOnline }
          >
            <Avatar
              alt='User Avatar'
              src={ avatarUrl }
              className='chat-header-avatar'
            />
          </Badge>
          <div className='title'>
            <h4>{ firstName } { lastName }</h4>
            <p>{ isOnline ? 'Available now' : 'Offline' }</p>
          </div>
        </div>
      </div>
      ) }
    </div>
  )
}

Header.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  avatarUrl: PropTypes.string,
  loading: PropTypes.bool,
  backButton: PropTypes.bool,
  isOnline: PropTypes.bool
}

export default Header;