import React from "react";
import { Routes, Route } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary";
import {loadStripe} from '@stripe/stripe-js';
import ReactGA from 'react-ga4';
import { ThemeProvider } from "@mui/material";

import { UserContextProvider } from "./context";
import CreatorChat from "./views/Creator/Chat";
import CreatorHome from "./views/Creator/Home";
import CreatorSignup from './views/Creator/Signup';
import StripeAuth from './views/StripAuth';
// import FanLanding from './views/LandingPages/FanLanding';
import CreatorLanding from './views/LandingPages/CreatorLanding';
import Home from "./views/Home";
import usePageTracking from "./hooks/usePageTracking";
import CreatorRoute from "./components/CreatorRoute";
import PlatformRoute from "./components/PlatformRoute";
import AuthRoute from './components/AuthRoute';
import Faq from "./views/Faq/Faq";
import CreatorFaq from "./views/Faq/CreatorFaq";
import CreateWelcome from "./views/Creator/CreateWelcome";
import Account from "./views/Account";
import TermsOfService from "./views/Legal/TermsOfService";
import PrivacyPolicy from "./views/Legal/PrivacyPolicy";
import CommunityGuidelines from "./views/Legal/CommunityGuidelines";
import PlatformSignup from "./views/Plaform/Signup";
import theme from "./lib/theme";
import PlatformHome from "./views/Plaform/Home";
import Dashboard from "./views/Dashboard";
import SignupAuthRoute from "./components/SignupAuthRoute";
import FanHome from "./views/Fan/Home";
import PaymentMethod from "./views/Account/PaymentMethod";
// import AuthCallback from "./views/AuthCallback";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

const App = () => {
  usePageTracking();
  
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <UserContextProvider stripePromise={ stripePromise }>
          <Routes>
            {/* creator routes */}
            <Route exact path='/creator/chat/:chatId' element={<CreatorRoute />}>
              <Route exact path="/creator/chat/:chatId" element={<CreatorChat />} />
            </Route>
            <Route exact path="/creator/stripe/:redirectType" element={<CreatorRoute />}>
              <Route exact path="/creator/stripe/:redirectType" element={<StripeAuth />} />
            </Route>
            <Route path="/creator/welcome" element={<CreatorRoute />}>
              <Route path="/creator/welcome" element={<CreateWelcome />} />
            </Route>
            <Route path="/creator/home" element={<CreatorRoute />}>
              <Route path="/creator/home" element={<CreatorHome />} />
            </Route>
            <Route path="/fan/home" element={<AuthRoute />}>
              <Route path="/fan/home" element={<FanHome />} />
            </Route>
            <Route path="/creator/onboarding" element={<SignupAuthRoute />}>
              <Route exact path="/creator/onboarding" element={<CreatorSignup />} />
            </Route>
            <Route path="/platform/home" element={<PlatformRoute />}>
              <Route path="/platform/home" element={<PlatformHome />} />
            </Route>
            <Route exact path="/platform/stripe/:redirectType" element={<PlatformRoute />}>
              <Route exact path="/platform/stripe/:redirectType" element={<StripeAuth />} />
            </Route>
            <Route path="/platform/onboarding" element={<SignupAuthRoute />}>
              <Route exact path="/platform/onboarding" element={<PlatformSignup />} />
            </Route>
            <Route path="/dashboard" element={<AuthRoute />}>
              <Route exact path="/dashboard" element={<Dashboard />} />
            </Route>
            <Route exact path="/account" element={<AuthRoute />}>
              <Route exact path="/account" element={<Account />} />
            </Route>
            <Route exact path="/account/payment" element={<AuthRoute />}>
              <Route exact path="/account/payment" element={<PaymentMethod />} />
            </Route>
            <Route path="/platform/:platform/onboarding" element={<SignupAuthRoute />}>
              <Route exact path="/platform/:platform/onboarding" element={<CreatorSignup />} />
            </Route>
            {/* unauthed routes */}
            <Route exact path="/" element={<CreatorLanding />} />
            {/* <Route exact path="/callback" element={<AuthCallback />} /> */}
            {/* <Route exact path="/fan" element={<FanLanding />} /> */}
            <Route exact path="/creator/faq" element={<CreatorFaq />} />
            <Route exact path="/terms" element={<TermsOfService />} />
            <Route exact path="/privacy" element={<PrivacyPolicy />} />
            <Route exact path="/communityguidelines" element={<CommunityGuidelines />} />
            <Route exact path="/faq" element={<Faq />} />
            <Route exact path="/:creatorInsta" element={<Home />} />
          </Routes>
        </UserContextProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export default App;