import React, { useContext } from "react";
import { UserContext } from "../../../../context";
import { Box, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import './index.css';

const OpenSettingsLeft = () => {
    const { logout } = useContext(UserContext); 
    const navigate = useNavigate();

    const goToSettings = () =>{
      navigate('/account');
    }

    const goToPayment = () =>{
      navigate('/account/payment');
    }

    return (
      <div className='settings-component'>
        <Box>
          <Stack direction='row' sx={{ mb: 3 }} alignItems='center'>
            <img 
              alt='Beep logo' 
              className='logo' 
              style={{ width: '50px' }}
              src='https://res.cloudinary.com/creator-tools/image/upload/w_500/v1649719884/static/DM_Icon_V2_1_byopfq_efcaau.png' 
            />
            <Typography variant='h4'>Settings</Typography>
          </Stack>
          <div className='settings-buttons'>
            <button onClick={goToSettings}>Beep Account</button>
            <button onClick={goToPayment}>Payment Method</button>
            <a href='mailto:beep@beepmehere.com'>
              <button>
                Help
              </button>
            </a>
            <button onClick={logout}>Logout</button>
          </div>
        </Box>
      </div>
    );
}

OpenSettingsLeft.propTypes = {
  onError: PropTypes.func,
  onStripeLogin: PropTypes.func
}

export default OpenSettingsLeft;