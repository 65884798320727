import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText, Input, InputLabel } from '@mui/material';
import { useField } from 'formik';
import './index.css';

const CustomInput = ({ 
  label,
  fullWidth = true,
  className,
  helperText,
  valid,
  ...props 
}) => {
  const [field, meta] = useField(props);
  
  return (
    <div className={ className }>
      <InputLabel 
        className='custom-input-label' 
        htmlFor={ `${ props.name }-input`}
      >
        { label }
      </InputLabel>
      <div className={ (meta.touched && meta.error) ? 'error-container' : '' } >
        <Input
          id={ `${ props.name }-input`}
          { ...field }
          { ...props }
          disableUnderline
          fullWidth={ fullWidth }
          className={ `custom-input ${ valid ? 'custom-input-valid' : '' }` }
          error={ !!(meta.touched && meta.error) }
        />
      </div>
      { !(meta.touched && meta.error) && helperText && <FormHelperText
          id={ `${ props.name }-helper-text`}
          className='helper-text'
        >
          { helperText }
        </FormHelperText> }
      {(meta.touched && meta.error) && <p className='error-message'>{meta.error}</p>}
    </div>
  )
}

CustomInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string
}

export default CustomInput;