import React, { useContext, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import styles from './Landing.module.css';
import logo from '../../img/landingPages/Logo.png';
import section1 from '../../img/landingPages/P1_5_BeepChat_1x-smaller.gif';
import section2 from '../../img/landingPages/DM_Price_Pop_Out_1x-smaller1.gif';
import section3 from '../../img/landingPages/Beep_Chat_Everywhere-smaller1.gif';
import section4 from '../../img/landingPages/earn.png';
import section5 from '../../img/landingPages/connect.png';
import useLocalStorage from "../../hooks/useLocalStorage";
import spacer from '../../img/landingPages/spacer.png';
import downArrow from '../../img/landingPages/down-arrow1.png';
import logo3d from '../../img/landingPages/Beep_Logo_3D_Shine_2x-smaller2.gif';
import { UserContext } from "../../context";
import { Input, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import CreatorFaq from "../Faq/CreatorFaq";

const CreatorLanding = () => {
  const { login } = useContext(UserContext);
  const sec1Ref = useRef();
  const sec2Ref = useRef();
  const sec3Ref = useRef();
  const sec4Ref = useRef();
  const sec5Ref = useRef();
  const sec6Ref = useRef();

  // eslint-disable-next-line no-unused-vars
  const [username, setUsername] = useLocalStorage('username', '');
  const navigate = useNavigate();

  // unsure about these paths:
  const signup = () =>{
    login({
      appState: {
        returnTo: `/creator/onboarding`,
      },
      prompt: 'login',
      screen_hint: 'signup',
    })
  }
  // const learnHow = () =>{
  //   navigate('/creator/faq');
  // }
  const goHome = () =>{
      navigate('/');
  }
    
  return(
    <div className={styles.background}>
      <div className={styles.landingPage}>
      <div className={styles.newLogo}>
          <img src={logo3d} alt="3d logo" />
        </div>
      
        

        <Stack justifyContent='flex-end' flexDirection='column' sx={{ height: '100vh' }}>
          <div className={styles.header}>
            <div className={styles.headerLogo} onClick={goHome}>
              <img src={logo}
                alt="beep logo" />
            </div>
            <div className={styles.headerButtons}>
              <button 
                className={styles.loginButton} 
                onClick={() => login({
                  appState: {
                    returnTo: '/dashboard',
                  }
                })}
              >LOG IN</button>
              <button className={styles.signupButton} onClick={signup}>SIGN UP</button>
            </div>
          </div>  
          <div>
          <div className={styles.topBox}>
            <h1>Monetize your expertise.</h1>
            <p>Join the first million creators on Beep who get paid to share their knowledge.</p>
            <div className={styles.headerButtonsBottomStacked}>
              <Input
                fullWidth={ false }
                className={styles.username}
                autoComplete='off'
                disableUnderline
                boxSizing='inherit'
                startAdornment={
                  <p className={styles.usernamePlaceholder}>beepmehere.com/</p>
                }
                placeholder='yourname'
                onChange={evt => setUsername(evt.target.value)}
              />
              <button className={styles.claimYourBeep} onClick={signup} >GET MY BEEP LINK</button>
              
            </div>
          </div>

          <div className={styles.center}>
            <img 
              src={downArrow} 
              className={styles.downArrow} 
              alt='see more'
              onClick={() => sec1Ref.current?.scrollIntoView({ behavior: 'smooth' })}
            />
          </div>
          </div>
          <img src={spacer} className={styles.smallSpacer} alt='spacer'/>
        </Stack>

        <Stack justifyContent='space-between' sx={{ height: 'calc(100vh - 80px)' }} ref={sec1Ref}>
          
          <div className={styles.section}>
            <div className={styles.sectionImage}>
              <img src={section1} alt='Get paid to respond' />
            </div>
            <div className={styles.sectionText}>
              <h2>A new pay-per-dm Business Chat.</h2>
              <p>Turn fans into customers and connect around your expertise</p>
            </div>
          </div>
          <div className={styles.center}>
            <img 
              src={downArrow} 
              className={styles.downArrow} 
              alt='see more'
              onClick={() => sec2Ref.current?.scrollIntoView({ behavior: 'smooth' })}
            />
          </div>
        </Stack>

        <Stack justifyContent='space-between' sx={{ height: 'calc(100vh - 80px)' }} ref={sec2Ref}>
        
          <div className={styles.sectionMiddle} >
            <div className={styles.sectionText}>
              <h2>Set your price.</h2>
              <p>Set a price per chat, always adjustable. Message back within the week, no pressure for immediate responses.</p>
            </div>
            <div className={styles.sectionImage}>
              <img src={section2} alt='Get paid to respond' />
            </div>
          </div>
          <div className={styles.center}>
            <img 
              src={downArrow} 
              className={styles.downArrow} 
              alt='see more'
              onClick={() => sec3Ref.current?.scrollIntoView({ behavior: 'smooth' })}
            />
          </div>
        </Stack>

        <Stack justifyContent='space-between' sx={{ height: 'calc(100vh - 80px)' }} ref={sec3Ref}>
        
          <div className={styles.section}>
            <div className={styles.sectionImage}>
              <img src={section3} alt='Set your price' />
            </div>
            <div className={styles.sectionText}>
              <h2>Add your Beep chat everywhere.</h2>
              <p>Copy/Paste your beep chat link to your social media, website, blog, or link in bio and start earning on beep.</p>
            </div>
          </div>
          <div className={styles.center}>
            <img 
              src={downArrow} 
              className={styles.downArrow} 
              alt='see more'
              onClick={() => sec4Ref.current?.scrollIntoView({ behavior: 'smooth' })}
            />
          </div>
        </Stack>

        <Stack justifyContent='space-between' sx={{ height: 'calc(100vh - 80px)' }} ref={sec4Ref}>
        
          <div className={styles.sectionMiddle}>
            <div className={styles.sectionText}>
              <h2>Get paid to respond.</h2>
              <p>Get paid on every reply, weed out spammers, and focus your time on real consumers and fans.</p>
            </div>
            <div className={styles.sectionImage}>
              <img src={section4} alt='Copy and paste your link anywhere' />
            </div>
          </div>
          <div className={styles.center}>
            <img 
              src={downArrow} 
              className={styles.downArrow} 
              alt='see more'
              onClick={() => sec5Ref.current?.scrollIntoView({ behavior: 'smooth' })}
            />
          </div>
        </Stack>

        <Stack justifyContent='space-between' sx={{ height: 'calc(100vh - 80px)' }} ref={sec5Ref}>
        
          <div className={styles.section}>   
          
            <div className={styles.sectionImage}>
              <img src={section5} alt='Copy and paste your link anywhere' />
            </div>
            <div className={styles.sectionText}>
              <h2>One inbox to rule them all.</h2>
              <p>All of your beep chats across every touch point go to one inbox to manage. 🙌</p>
            </div>
          </div>
          <div className={styles.center}>
            <img 
              src={downArrow} 
              className={styles.downArrow} 
              alt='see more'
              onClick={() => sec6Ref.current?.scrollIntoView({ behavior: 'smooth' })}
            />
          </div>
        </Stack>
      </div>
      
<Stack  className={styles.faqBox} ref={sec6Ref}>
  <CreatorFaq />
</Stack>
      <div className={styles.footer}>
        <div className={styles.smallCenteredButton} onClick={signup}>
          <img src={logo} alt="beep logo" />
        </div>
        {/* Get Priority Responses */}
        {/* <Typography sx={{mb: 2}}>
          <Link to='/creator/faq' className={styles.footerLink}>FAQ</Link>
        </Typography> */}
        <Typography sx={{mb: 2}}>
          <a href='mailto:beep@beepmehere.com' className={styles.footerLink}>Contact Us</a>
        </Typography>
        <Typography sx={{mb: 2}}>
          <Link to='/terms' className={styles.footerLink}>Terms of Service</Link>
        </Typography>
        <Typography>
          <Link to='/privacy' className={styles.footerLink}>Privacy Policy</Link>
        </Typography>
      </div>
    </div>
  );
}

export default CreatorLanding;