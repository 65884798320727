import React, { useContext, useEffect, useRef, useState } from "react";
import { Alert, Box, Divider, Skeleton, Snackbar, Stack, Tab, Tabs } from '@mui/material';

import CreatorChatList from "../../../components/CreatorChatList";
import CreatorHeader from "./CreatorHeader";
import './index.css';
import { UserContext } from "../../../context";
import { useNavigate, useSearchParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { useCreatorAnalytics } from "../../../hooks/useAnalytics";
import useLazyFetch from "../../../hooks/useLazyFetch";
import CopyLink from "../../../components/CopyLink";
// import GetMessages from "./GetMessages";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const CreatorHome = () => {
  const { user, chatClient, refreshUser, setupChatClient, loading } = useContext(UserContext);
  const [error, setError] = useState();
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { 
    data: conversationData, 
    loading: conversationsLoading, 
    refetch: refetchConversations
  } = useFetch(`/creator/${ user?.creator.id }/conversations`);
  const { 
    data: balanceData,
    refetch: refetchBalance
  } = useFetch(`/creator/${ user?.creator.id }/balance`);
  const [getLink, { loading: linkLoading }] = useLazyFetch(
    `/stripe/link/login?redirectUrl=${window.location.origin}`
  );
  const sendCreatorEvent = useCreatorAnalytics();
  const conversations = (conversationData?.conversations || []).reduce((acc, chat) => {
    if(chat.hasReplied) {
      return { ...acc, replied: [chat, ...acc.replied] };
    } else {
      return { ...acc, needsReply: [...acc.needsReply, chat] };
    }
  }, {
    needsReply: [],
    replied: []
  });
  const creatorLink = `${window.location.host}/${user.creator.handle}`;
  const coinDestinationRef = useRef();

  // useEffect(() => {
  //   if(!conversationsLoading && conversationData?.conversations?.length === 0) {
  //     setTab(1)
  //   }
  // }, [conversationsLoading, conversationData])
  useEffect(() => {
    if(user?.creator?.id) setupChatClient(user?.creator?.id);
  }, [setupChatClient, user?.creator?.id]);
  useEffect(() => {
    if(user && !user?.creator?.isStripeSetup) navigate('/creator/onboarding');
  }, [navigate, user]);

  useEffect(() => {
    if(searchParams.get('refreshUser')) {
      sendCreatorEvent({ action: 'stripe connect success' });
      refreshUser().then(() => {
        navigate(window.location.pathname);
      });
    }
  }, [navigate, refreshUser, searchParams, sendCreatorEvent]);

  const handleStripeLogin = async () => {
    if(linkLoading) return;
    const { data } = await getLink();
    if(data?.url) window.location = data.url;
  }

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div className='home'>
      <Snackbar
        open={ !!error } 
        autoHideDuration={ 3000 } 
        onClose={ setError }
      >
        <Alert 
          className='error-alert'
          onClose={ setError } 
          severity="error" 
          variant="filled"
        >
          { error }
        </Alert>
      </Snackbar>
      <Stack sx={{ height: 1, position: 'relative', overflow: 'hidden' }}>
        <CreatorHeader 
          avatarUrl={ user?.avatarUrl }
          onError={ setError }
          onStripeLogin={ handleStripeLogin }
          refetchBalance={ refetchBalance }
          balance={ balanceData?.available }
          pendingBalance={ balanceData?.pending }
          bankRef={ coinDestinationRef }
          isStripeSetup={ user?.creator?.isStripeSetup }
        />
        { loading || conversationsLoading ? (
          <>
            <Skeleton variant="rectangular" className='chat-preview-loading' height={91} />
            <Skeleton variant="rectangular" className='chat-preview-loading' height={91} />
            <Skeleton variant="rectangular" className='chat-preview-loading' height={91} />
            <Skeleton variant="rectangular" className='chat-preview-loading' height={91} />
            <Skeleton variant="rectangular" className='chat-preview-loading' height={91} />
          </>
        ) : (
          <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs 
                value={tab} 
                onChange={handleTabChange}
                centered
                variant='fullWidth'
              >
                <Tab label="My Inbox" />
                {/* <Tab label="Get Messages" /> */}
              </Tabs>
            </Box>
            <TabPanel value={tab} index={0} style={{
              overflow: 'auto',
              height: '100%',
              paddingBottom: '40px'
            }}>
              { chatClient && <CreatorChatList
                className={ `creator-chat-list` } 
                onGetMessagesClick={() => setTab(1)}
                conversations={ conversations }
                loading={ conversationsLoading }
                refetchConversations={ refetchConversations }
                refetchBalance={ refetchBalance }
                onError={ setError }
                bankLocation={{
                  x: coinDestinationRef.current?.offsetLeft,
                  y: coinDestinationRef.current?.offsetTop
                }}
              /> }
            </TabPanel>
            <Divider />
            <CopyLink link={ creatorLink } />
            {/* <TabPanel value={tab} index={1}>
              <GetMessages 
                creatorLink={creatorLink} 
                avatarUrl={ user.avatarUrl }
              />
            </TabPanel> */}
          </>
        )}
      </Stack>
    </div>
  )
}

export default CreatorHome;