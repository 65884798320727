import React, { useContext, useState } from "react";
import { Alert, Snackbar } from "@mui/material";

import './index.css';
import CreatorSignupForm from "./CreatorSignupForm";
import useMutate from "../../../hooks/useMutate";
import { useCreatorAnalytics } from "../../../hooks/useAnalytics";
import { UserContext } from "../../../context";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams, useSearchParams } from "react-router-dom";

const CreatorSignup = () => {
  const { refreshUser } = useContext(UserContext);
  const { user } = useAuth0();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [signup] = useMutate('/signup');
  const { platform } = useParams();
  const [searchParams] = useSearchParams();
  const creatorPlatformId = searchParams.get('identity');
  const sendCreatorEvent = useCreatorAnalytics();

  const handleSubmit = async (values) => {
    setLoading(true);
    return signup({
      isCreator: true,
      handle: values.username,
      email: user?.email,
      platformSlug: platform,
      creatorPlatformId,
      ...values
    }).then(async (response) => {
      if(response?.error) {
        setError(response?.error || 'Something went wrong. Please try again.');
        setLoading(false);
        return;
      }
      sendCreatorEvent({ action: 'signup success' })
      
      await refreshUser();
      setLoading(false);
    });
  }

  return (
    <div>
      <Snackbar 
        className='error'
        open={ !!error } 
        autoHideDuration={ 3000 } 
        onClose={ () => setError() }
      >
        <Alert 
          className='error-alert'
          onClose={ () => setError() } 
          severity="error" 
          variant="filled"
        >
          { error } 
        </Alert>
      </Snackbar>
      <CreatorSignupForm
        onSubmit={ handleSubmit }
        loading={ loading }
      />
    </div>
  )
}

export default CreatorSignup;