import { Alert, Snackbar } from '@mui/material';
import React, { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context';
import useMutate from '../../hooks/useMutate';
import UpdateSettingsForm from './UpdateSettingsForm';
import styles from './Account.module.css';

const Account = () => {
  const { user, refreshUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const navigate = useNavigate();
  const [updateUser] = useMutate(`/user/${ user?.id }`, { method: 'PUT' })
  const [updateCreator] = useMutate(`/creator/${ user?.creator?.id }`, { method: 'PUT' })

  const handleEdit = useCallback(async (values) => {
    setLoading(true);
    const response = user?.isCreator
      ? await updateCreator({
        ...values,
        handle: values.username
      })
      : await updateUser(values)
    setLoading(false);
    if(response?.error) {
      setError(response?.error || 'Something went wrong. Please try again.');
      return;
    }
    refreshUser();
    navigate(-1);
  }, [updateCreator, updateUser, user?.isCreator, refreshUser, navigate]);
  
  return (
    <div className={ styles.container }>
      <Snackbar
        open={ !!error } 
        autoHideDuration={ 3000 } 
        onClose={ setError }
      >
        <Alert 
          className='error-alert'
          onClose={ setError } 
          severity="error" 
          variant="filled"
        >
          { error }
        </Alert>
      </Snackbar>
      { user && <UpdateSettingsForm
        initialValues={{
          avatarUrl: user?.avatarUrl,
          firstName: user?.firstName,
          lastName: user?.lastName,
          phoneNumber: user?.phoneNumber,
          phoneNotifications: user?.phoneNotifications,
          username: user?.username,
          email: user?.email,
        }}
        userId={user?.id}
        onSubmit={ handleEdit }
        loading={ loading }
        onError={ setError }
      /> }
    </div>
  )
}

export default Account;