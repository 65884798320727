import BottomDrawer from "../../../components/BottomDrawer";
import { Box, Stack, Typography } from "@mui/material";
import checkmark from '../../../img/checkmark.svg';
import CustomButton from "../../../components/CustomButton";

const WelcomeModal = ({ isOpen, onClose, creator }) => {
  return (
    <BottomDrawer 
      isOpen={isOpen}
      canExit={false}
      showHeader={true}
      title='Welcome to Beep!'
    >
      <Stack direction='row'>
        <img src={checkmark} alt='Check mark' />
        <Typography>Fast, personalized responses from your favorite creators and experts</Typography>
      </Stack>
      <Stack direction='row'>
        <img src={checkmark} alt='Check mark' />
        <Typography>Receive responses within 7 days or be automatically refunded</Typography>
      </Stack>
      <Typography
        sx={{ textAlign: 'center', my: 2, mb: 3 }}
      >Send a DM to get started 👇</Typography>
      <Box sx={{ mb: 2 }}>
        <CustomButton onSubmit={onClose}>Message { creator?.firstName }</CustomButton>
      </Box>
    </BottomDrawer>
  );
}

export default WelcomeModal;