import React, { createContext, useEffect, useState, useCallback } from "react";
import { Client } from '@twilio/conversations';
import PropTypes from 'prop-types';
import { useAuth0 } from "@auth0/auth0-react";
import { Alert, Snackbar } from "@mui/material";
import axios from "axios";
import config from '../conf';
import { useNavigate } from "react-router-dom";
// import auth0 from 'auth0-js';

const UserContext = createContext();

const UserContextProvider = ({ stripePromise, children }) => {
  const [chatClient, setClient] = useState();
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { 
    getAccessTokenSilently, 
    loginWithRedirect, 
    logout: auth0Logout, 
    user: auth0User, 
    isAuthenticated, 
    isLoading: isAuthenticating
  } = useAuth0();
  const navigate = useNavigate();
  // const auth = new auth0.WebAuth({
  //   clientID: process.env.REACT_APP_AUTH0_CLIENT_ID, 
  //   domain: process.env.REACT_APP_AUTH0_DOMAIN,
  //   redirectUri: `${window.location.origin}/callback`,
  //   responseType: 'token'
  // });

  const refreshUser = async () => {
    localStorage.removeItem('user');
    await getUser();
  }

  const getUser = useCallback(async () => {
    if(!auth0User) return;
    console.log('fetching user');
    const response = await axios({
      method: 'GET',
      url: `${ config.API_ENDPOINT }/whoami`,
      headers: {
        Authorization: `Bearer ${ await getAccessTokenSilently() }`,
      }
    });
    localStorage.setItem('user', JSON.stringify(response.data?.user));
    setUser(response.data?.user);
  }, [auth0User, getAccessTokenSilently]);

  const setupChatClient = useCallback(async (creatorId) => {
    const response = await axios({
      method: 'get',
      url: `${ config.API_ENDPOINT }/chat/token/${creatorId}`,
      headers: {
        Authorization: `Bearer ${ await getAccessTokenSilently() }`
      }
    });
    const chatClient = new Client(response.data?.token);
  
    chatClient.on('stateChanged', (state) => {
      console.log('chat state', state);
      if (state === 'initialized') {
        setClient(chatClient);
        return;
      }
      setError('Unable to load chat. Please reload.')
    });
  }, [getAccessTokenSilently]);
  
  const logout = (redirect = true) => {
    setUser(null);
    setClient(null);
    localStorage.removeItem('user');
    auth0Logout();
    if(redirect) navigate('/');
  }

  // auto set user/init when logged in
  useEffect(() => {
    const init = async () => {
      if(!auth0User) return;
      setLoading(true);
      // if user, init chatClient
      const storedUser = localStorage.getItem('user');
      // if no user data or it doesn't match, fetch
      if(storedUser !== 'undefined' && typeof storedUser === 'string') {
        const parsedUser = JSON.parse(storedUser);
        if(parsedUser.id === auth0User.sub) {
          setUser(parsedUser);
          setLoading(false);
          return;
        }
      }
      
      await getUser();
      setLoading(false);
    }

    init();
  }, [auth0User, getUser, setLoading]);
  
  return (
    <UserContext.Provider
      value={{ 
        chatClient, 
        user, 
        login: loginWithRedirect, 
        logout,
        // auth,
        isAuthenticated,
        isAuthenticating,
        fetchingUser: loading,
        stripePromise,
        refreshUser,
        setupChatClient,
        onError: setError
      }}
    >
      { children }
      <Snackbar 
        open={ !!error } 
        onClose={ () => setError() }
      >
        <Alert 
          className='error-alert'
          onClose={ () => setError() } 
          severity="error" 
          variant="filled"
        >
          <p>{error}</p>
        </Alert>
      </Snackbar>
    </UserContext.Provider>
  )
}

UserContextProvider.propTypes = {
  children: PropTypes.node,
  stripePromise: PropTypes.object
}

export { UserContext, UserContextProvider }