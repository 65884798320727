import React, { useState } from "react";
import PropTypes from 'prop-types';
import './index.css';
import { Avatar, Stack, SwipeableDrawer, Typography } from "@mui/material";
import styles from './CreatorHeader.module.css';
import OpenSettingsLeft from "../OpenSettingsLeft"
import { ReactComponent as PiggyBank } from '../../../../img/piggy-bank.svg';
import cns from 'classnames';
import BottomDrawer from "../../../../components/BottomDrawer";
import CustomButton from "../../../../components/CustomButton";
import useMutate from "../../../../hooks/useMutate";

const CreatorHeader = ({ 
  avatarUrl, 
  onError, 
  onStripeLogin, 
  refetchBalance, 
  bankRef, 
  balance,
  pendingBalance,
  isStripeSetup 
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const [payoutsOpen, setPayoutsOpen] = useState(false);
  const [payout, { loading }] = useMutate(`/creator/payout`, { method: 'POST' });

  const fee = Math.round(250 + ((balance?.amount || 0) * 0.025));

  const handleTransfer = () => {
    payout({
      amount: balance?.amount
    }).then(() => {
      refetchBalance();
      setPayoutsOpen(false);
    });
  }

  const total = (balance?.amount || 0) + (pendingBalance?.amount || 0);

  return (
    <div className='creator-header'>
      <div onClick={handleOpen}>
        <Avatar
          alt='User Avatar'
          src={ avatarUrl }
          className={styles.avatar}
        />
      </div>
      <div ref={ bankRef } className={
        cns(styles.earnings)
      } onClick={() => setPayoutsOpen(true)}>
        <PiggyBank />
        { isStripeSetup && total >= 0 && <p>${ (total * .01).toFixed(2) }</p> }
      </div>
      <BottomDrawer
        isOpen={payoutsOpen}
        onClose={() => setPayoutsOpen(false)}
      >
        <Typography variant='h5' sx={{ mb: 2 }}>Transfer Balance</Typography>
        <Stack direction='row' justifyContent='space-between' sx={{ mb: 1 }}>
          <Typography>Pending balance</Typography>
          <Typography>${ (pendingBalance?.amount * .01).toFixed(2) }</Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between' sx={{ mb: balance?.amount > 2000 ? 1 : 3 }}>
          <Typography>Available to transfer</Typography>
          <Typography>${ (balance?.amount * .01).toFixed(2) }</Typography>
        </Stack>
        { balance?.amount > 2000 && <Stack direction='row' justifyContent='space-between' sx={{ mb: 2 }}>
          <Typography>Stripe fees</Typography>
          <Typography>({ (fee * .01).toFixed(2) })</Typography>
        </Stack> }
        { balance?.amount > 2000 && <Stack direction='row' justifyContent='space-between' sx={{ mb: 3 }}>
          <Typography><b>Amount to transfer</b></Typography>
          <Typography><b>${ ((balance?.amount - fee) * .01).toFixed(2) }</b></Typography>
        </Stack> }
        <CustomButton 
          disabled={balance?.amount < 2000}
          onSubmit={handleTransfer}
          loading={loading}
        >Transfer</CustomButton>
        { balance?.amount < 2000 && <Typography
          sx={{ mt: 2, color: 'red', textAlign: 'center' }}
        >A minimum available amount of $20 is required to transfer.</Typography> }
      </BottomDrawer>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        className='settings-drawer'
        open={ open }
        onOpen={ () => setOpen(true) }
        onClose={ handleClose }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{
          square: false,
          className: 'creator-menu'
        }}
      >
        <OpenSettingsLeft onError={ onError } onStripeLogin={onStripeLogin} />
      </SwipeableDrawer>
    </div>
  )
}

CreatorHeader.propTypes = {
  avatarUrl: PropTypes.string,
  onError: PropTypes.func,
  onStripeLogin: PropTypes.func
}

export default CreatorHeader;