import React, { useEffect } from "react";
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router-dom";
import { useCreatorAnalytics } from "../../hooks/useAnalytics";
import { CircularProgress } from "@mui/material";

const StripeAuth = () => {
  const { redirectType } = useParams();
  const { data } = useFetch(
    `/stripe/link/${ redirectType }?redirectUrl=${window.location.origin}`
  );
  const sendCreatorEvent = useCreatorAnalytics();
  
  useEffect(() => {
    sendCreatorEvent({
      action: `stripe ${ redirectType } click`
    })
  }, [redirectType, sendCreatorEvent])

  useEffect(() => {
    if(data && data.url) {
      window.location.replace(data.url);
    }
  }, [data]);

  return (
    <div className='center progress'>
      <CircularProgress />
    </div>
  )
}

export default StripeAuth;