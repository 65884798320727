import { Box, Drawer, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

import gradient from '../../img/rectangle.png';
import styles from './BottomDrawer.module.css';
import { ReactComponent as Exit } from '../../img/exit.svg';

const BottomDrawer = ({
  isOpen,
  canExit = true,
  onClose = () => {},
  children,
  showHeader,
  title,
  ...props
}) => {

  return (
    <Drawer
      { ...props }
      anchor='bottom'
      open={isOpen}
      onClose={ onClose }
      variant="temporary"
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        square: false,
        className: styles.drawerPaper
      }}
    >
      { showHeader && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography 
          variant='h4'
          sx={{ position: 'absolute', color: 'white' }}
        >{title}</Typography>
        <img 
          src={gradient}
          style={{ width: '100%' }}
          alt='Beep Gradient' 
        />
      </Box> }
      <Box sx={{
        margin: showHeader ? '-30px 0 0 0' : 0,
        padding: '30px',
        borderTopLeftRadius: '30px !important',
        borderTopRightRadius: '30px !important',
        backgroundColor: 'white'
      }}>
        { canExit && <Exit className={ styles.exit } onClick={ onClose } /> }
        { children }
      </Box>
    </Drawer>
  )
}

BottomDrawer.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node
}

export default BottomDrawer;