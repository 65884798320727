import React, { useContext, useState } from "react";
import { UserContext } from "../../../../context";
import { Box, Checkbox, FormControlLabel, Input, InputAdornment } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import './index.css';
import useMutate from "../../../../hooks/useMutate";
import logo from '../../../../img/logo.png';
import CustomButton from "../../../../components/CustomButton";

const OpenSettingsLeft = ({ onError, onStripeLogin }) => {
    const { user, refreshUser, logout } = useContext(UserContext); 
    const [dmPrice, setDmPrice] = useState(user.creator.dmPrice);
    const [firstMessageFree, setFirstMessageFree] = useState(user.creator?.firstMessageFree);
    const [updateCreator] = useMutate(`/creator/${ user.creator.id }`, { method: 'PUT' });
    const navigate = useNavigate();

    const handleChange = (e) =>{
      const price = Number(e.target.value);
      if(price <= 0) {
        setDmPrice();
      } else {
        setDmPrice(price);
      }
    }
    const goToSettings = () =>{
      navigate('/account');
    }

    const handleSubmit = async (e) =>{
      e.preventDefault();
      // return early if there are no updates
      if (dmPrice >= 5) {
        const { error } = await updateCreator({ dmPrice, firstMessageFree });
        if(error) {
          onError(error.message)
          return;
        }
        refreshUser();
        return;
      }
      // set error
      onError('Price should be greater than or equal to $5')
    }

    return (
      <div className='settings-component'>
        <Box>
          <div className='settings-dm-price'>
            <div className='settings-dm-price-image'>
              <img src={logo} alt="logo" />
            </div>
            <h3>Set DM price</h3>

            <Input
              className='dm-price-input'
              name='dmPrice'
              type='number'
              disableUnderline
              onChange={ handleChange }
              value={ dmPrice }
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
            />
            
            <p className='settings-small-text'>This is the amount your fans pay to send you a direct message</p>
            
            <FormControlLabel
                className='creator-signup-input'
                control={<Checkbox
                  checked={ firstMessageFree }
                  name='firstMessageFree'
                  onChange={ (evt) => setFirstMessageFree(evt.target.checked) }
                  inputProps={{ 'aria-label': 'controlled' }}
                />} 
                label='Make the first message free for fans' 
                classes={{
                  label: 'checkbox-label'
                }}
              />
            <CustomButton
              type='button'
              onSubmit={ handleSubmit }
            >Save</CustomButton>
          </div>
          {/* <div className="campaign">
            <div className="arrows"><img src="../../../../arrows.png" alt="arrows" /></div>
              
            <h3>Create Campaign</h3>
            <p className="smaller">Send a message, video or file to lots of your fans at once</p>
          </div> */}
          <div className='settings-buttons'>
            <button onClick={onStripeLogin}>My earnings</button>
            <button onClick={goToSettings}>Beep Account</button>
            <button onClick={() => navigate('/creator/welcome')}>Welcome Message</button>
            <a href='mailto:beep@beepmehere.com'>
              <button>
                Help
              </button>
            </a>
            <button onClick={logout}>Logout</button>
          </div>
        </Box>
      </div>
    );
}

OpenSettingsLeft.propTypes = {
  onError: PropTypes.func,
  onStripeLogin: PropTypes.func
}

export default OpenSettingsLeft;