import React, { useState } from 'react';
import cns from 'classnames';
import styles from './CopyLink.module.css';
import logo from '../../img/logo.png';

const truncate = (paragraph) => {
  if (paragraph.length > 52) {
    return paragraph.substring(0, 52) + '...';
  } else {
    return paragraph;
  }
}

const CopyLink = ({ link, className }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(link)
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }

  return (
    <div className={ cns(styles.creatorLink, className) } onClick={ handleCopy } >
      <img src={logo} alt='Logo' height={40} />
      <p>{ truncate(link) }</p>
      <div className={styles.creatorLinkButton}>{ copied ? 'Copied' : 'Copy' }</div>
    </div>
  )
}

export default CopyLink;
