import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export const theme = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 960,
            lg: 1280,
            xl: 1920
        },
    },
    palette: {
        primary: {
            main: "#5113e7",
            light: "#000",
            dark: "#000",
            contrastText: "#fff",
        },
        // secondary: {
        //     main: "#fff",
        //     light: "#000",
        //     dark: "#000",
        //     contrastText: "#fff",
        // },
        // error: {
        //     main: "#fff",
        //     light: "#000",
        //     dark: "#000",
        //     contrastText: "#fff",
        // },
        // warning: {
        //     main: "#fff",
        //     light: "#000",
        //     dark: "#000",
        //     contrastText: "#fff",
        // },
        // info: {
        //     main: "#fff",
        //     light: "#000",
        //     dark: "#000",
        //     contrastText: "#fff",
        // },
        // success: {
        //     main: "#fff",
        //     light: "#000",
        //     dark: "#000",
        //     contrastText: "#fff",
        // },
        purple: {
          main: '#5113e7'
        },
        grey: {
          main: "#9D9DB5",
          // light: "#000",
          // dark: "#000",
          // contrastText: "#fff",
        },
    },
    components: {
      // https://mui.com/material-ui/customization/theme-components/#global-style-overrides
      // component overrides here
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 700,
          fontSize: '18px',
          color: "#9D9DB5"
        },
      },
    }
  },
};

export default responsiveFontSizes(createTheme(theme));
