import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MuiPhoneNumber from 'material-ui-phone-number';

import styles from './UpdateSettingsForm.module.css';
import CustomInput from '../../../components/CustomInput';
import CustomButton from '../../../components/CustomButton';
import BackButton from '../../../components/BackButton';
import AvatarUpload from '../../../components/AvatarUpload';
import useLazyFetch from '../../../hooks/useLazyFetch';
import BottomDrawer from '../../../components/BottomDrawer';
import useMutate from '../../../hooks/useMutate';
import { UserContext } from '../../../context';

const usernameRules = /^(?=.{3})[A-Za-z\d_-]+$/ig;
const phoneRules = /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/;

const validationSchema = Yup.object().shape({
  username: Yup
    .string()
    .min(3, 'Must be at least 3 letters or numbers')
    .max(25, 'Must be 25 characters or less')
    .matches(usernameRules, {message: 'Your username must contain only letters or numbers'})
    .required('Username is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required'),
  phoneNumber: Yup
    .string()
    .matches(phoneRules, {message: 'This is not a valid phone number'})
    .required('Phone number is required'),
  phoneNotifications: Yup.boolean(),
  emailNotifications: Yup.boolean()
})

const UpdateSettingsForm = ({
  userId,
  values,
  handleSubmit,
  setFieldValue,
  onError,
  errors,
  touched,
  loading,
  setFieldTouched,
  setFieldError
}) => {
  const { logout } = useContext(UserContext);
  const [validUsername, setValidUsername] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteAccount, { loading: deleting }] = useMutate(`/user/${userId}`, {
    method: 'delete'
  });
  const [isUsernameAvailable, { loading: usernameLoading }] = useLazyFetch(`/username/available/${ values.username }`, { auth: false });
  const isValid = values.username && values.email
    && values.firstName && values.lastName && values.phoneNumber
    && !errors.email && !errors.firstName && !errors.lastName
    && !errors.username && !errors.phoneNumber

  const handleFormSubmit = useCallback((event) => {
    event.preventDefault();
    if(values.email && values.firstName && values.lastName) {
      handleSubmit(values);
    } else {
      onError('Missing required input');
    }
  }, [handleSubmit, onError, values])

  const handleSuccessfulUpload = useCallback((url) => {
    setFieldValue('avatarUrl', url)
  }, [setFieldValue])

  const handlePhoneInput = useCallback((value) => {
    let phoneNumber = value.replace(/-|\s|([()])/g,"");
    setFieldValue('phoneNumber', phoneNumber);
  }, [setFieldValue])

  const handlePhoneNotifications = useCallback((evt) => {
    setFieldValue('phoneNotifications', evt.target.checked);
  }, [setFieldValue])

  const handleEmailNotifications = useCallback((evt) => {
    setFieldValue('emailNotifications', evt.target.checked);
  }, [setFieldValue]);

  const handleUsernameValid = useCallback(async () => {
    if(!values.username) return;
    setFieldTouched('username', true);
    const { data } = await isUsernameAvailable();
    if(data?.available) {
      setValidUsername(true);
      setFieldError('username', '');
    } else {
      setValidUsername(false);
      setFieldError('username', 'Username is not available');
    }
  }, [isUsernameAvailable, setFieldError, values.username, setFieldTouched]);

  return (
    <form onSubmit={ handleFormSubmit } className={styles.container}>
      <div className={ styles.header }>
        <BackButton />
        <h3 className={ styles.title }>Beep Account</h3>
        <div className={ styles.placeholder } />
      </div>
      <div className={ styles.form }>
        <div className={styles.avatarContainer}>
          <AvatarUpload 
            onSuccessfulUpload={ handleSuccessfulUpload }
            url={ values.avatarUrl }
          />
        </div>
        <div className={styles.usernameGroup}>
          <CustomInput 
            name='username' 
            label='USERNAME'
            className={styles.username}
            valid={ !errors.username && validUsername }
            onBlur={ handleUsernameValid }
            helperText={ usernameLoading ? 'Checking availability...' : 'Can only contain letters and numbers' }
            endAdornment={
              validUsername && !errors.username 
                ? <CheckCircleOutlineIcon color='success' /> 
                : null
            }
          />
        </div>
        <div className={styles.nameGroup}>  
          <CustomInput 
            name='firstName' 
            label='FIRST NAME' 
            className={styles.nameGroupLeft}
            fullWidth={ false }
          />
          <CustomInput 
            name='lastName' 
            label='LAST NAME'
            fullWidth={ false }
          />
        </div>
        <MuiPhoneNumber
          onChange = { handlePhoneInput }
          name='phoneNumber'
          label='ENTER PHONE NUMBER'
          defaultCountry="us"
          fullWidth
          value={ values.phoneNumber }
        />
        {errors.phoneNumber && touched.phoneNumber && <p className={styles.errorMessage}>{errors.phoneNumber}</p>}
        <FormControlLabel
          className={styles.modalInput}
          control={<Checkbox 
            checked={values.phoneNotifications}
            onChange={handlePhoneNotifications}
            name='phoneNotifications'
          />} 
          label='Get notified via SMS when you have a new message' 
          classes={{
            label: 'checkbox-label'
          }}
        />
        <CustomInput 
          name='email' 
          label='EMAIL' 
          className={styles.modalInput}
        />
        <FormControlLabel
          className={styles.modalInput}
          control={<Checkbox 
            checked={values.emailNotifications}
            onChange={handleEmailNotifications}
            name='emailNotifications'
          />} 
          label='Get notified via email when you have a new message' 
          classes={{
            label: 'checkbox-label'
          }}
        />
      </div>
      <BottomDrawer
        isOpen={confirmOpen}
        onClose={() => setConfirmOpen(false)}
      >
        <h3>Are you sure you want to delete your account?</h3>
        <p>This action cannot be undone.</p>
        <Stack gap={1} sx={{ marginTop: 2 }}>
          <CustomButton
            type='submit'
            disabled={ deleting }
            loading={ deleting }
            onSubmit={() => {
              deleteAccount({}).then(logout);
            }}
          >
            Delete Account
          </CustomButton>
          <CustomButton
            type='submit'
            variant='secondary'
            disabled={ deleting }
            loading={ deleting }
            onSubmit={() => setConfirmOpen(false)}
          >
            Cancel
          </CustomButton>
        </Stack>
      </BottomDrawer>
      <div className={ styles.submit }>
        <CustomButton
          type='submit'
          disabled={ !isValid }
          loading={ loading }
        >
          Save Changes
        </CustomButton>
      </div>
      <Typography
        onClick={() => setConfirmOpen(true)}
        style={{
          textAlign: 'center',
          color: 'red',
          marginBottom: '40px',
          cursor: 'pointer'
        }}
      >
        Delete Account
      </Typography>
    </form>
  )
}

UpdateSettingsForm.propTypes = {
  isOpen: PropTypes.bool,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  onLoginClick: PropTypes.func,
  onError: PropTypes.func,
  errors: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
    phoneNumber: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    username: PropTypes.string
  }),
  touched: PropTypes.shape({
    email: PropTypes.bool,
    password: PropTypes.bool,
    phoneNumber: PropTypes.bool,
    firstName: PropTypes.bool,
    lastName: PropTypes.bool,
    username: PropTypes.bool
  }),
  values: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
    phoneNumber: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    username: PropTypes.string
  }),
  loading: PropTypes.bool,
  onClose: PropTypes.func
}

export default withFormik({
  mapPropsToValues: ({ initialValues = {} }) => (initialValues),
  validationSchema,
  handleSubmit: (values, { setSubmitting, props: { onSubmit } }) => {
    onSubmit(values);
    setSubmitting(false);
  },
  displayName: 'UpdateSettingsForm'
})(UpdateSettingsForm);
