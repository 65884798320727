import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Alert, IconButton, Snackbar, Typography } from '@mui/material';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import CustomButton from '../../../../components/CustomButton';
import CustomInput from '../../../../components/CustomInput';
import './index.css';
import { usePlatformAnalytics } from '../../../../hooks/useAnalytics';
import { ReactComponent as BeepLogo } from '../../../../img/logo-with-text.svg';
import { ReactComponent as BackArrow } from '../../../../img/back-arrow.svg';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../context';
import { Box, Stack } from '@mui/system';
import stripeLogo from '../../../../img/stripe.png';

const INITIAL_VALUES = {
  termsAndConditions: false,
  dmFeePercentage: 20
}

const usernameRules = /^(?=.{3})[A-Za-z\d_-]+$/ig;
// eslint-disable-next-line no-useless-escape
const URL = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i

const validationSchema = Yup.object().shape({
  redirectUrl: Yup.string().matches(URL, 'Enter a valid url'),
  slug: Yup
    .string()
    .min(3, 'Must be at least 3 letters or numbers')
    .max(25, 'Must be 25 characters or less')
    .matches(usernameRules, {message: 'Your handle must contain only letters or numbers'})
    .required('Platform handle is required'),
  name: Yup.string().required('Name is required'),
  dmFeePercentage: Yup.number().positive('Your DM fee must be a positive number').required('DM fees is required'),
})

const getStepHeader = (step) => {
  switch(step) {
    case 2:
      return 'Tell us about the platform';
    case 3:
      return 'Set platform settings'
    case 4:
        return <img src={ stripeLogo } alt='Earn with Stripe' width={ 115 } />;
    default: return 'Choose a platform handle';
  }
}

const getStepSubtitle = (step) => {
  switch(step) {
    case 4:
      return 'Almost done! To start earning, create a Stripe account.'
    default:
      return;
  }
}

const PlatformSignupForm = ({ 
  values,
  errors,
  setFieldError,
  setSubmitting,
  handleSubmit,
  loading,
  setFieldTouched,
  isSubmitting
}) => {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [step, setStep] = useState(1);
  // const [validUsername, setValidUsername] = useState(false);
  // const [isUsernameAvailable, { loading: usernameLoading }] = useLazyFetch(`/username/available/${ 
  //   values.username 
  // }`, { auth: false });
  const sendPlatformEvent = usePlatformAnalytics();
  const { user } = useContext(UserContext);

  // const handleUsernameValid = useCallback(async () => {
  //   setFieldTouched('username', true);
  //   if(!values.username || errors?.username) return;
  //   const { data } = await isUsernameAvailable();
  //   if(data?.available) {
  //     setValidUsername(true);
  //     setFieldError('username', '');
  //     return true;
  //   } else {
  //     setValidUsername(false);
  //     setFieldError('username', 'Username is not available');
  //     return false;
  //   }
  // }, [isUsernameAvailable, setFieldError, values.username, setFieldTouched, errors]);

  // if onboarding is complete, redirect to home
  useEffect(() => {
    if(user?.onboardingComplete) {
      if(user?.creator?.isStripeSetup) {
        navigate('/platform/home');
      } else {
        setStep(4);
      }
    }
  }, [user, navigate])

  const setMissingField = useCallback((field) => {
    setFieldTouched(field, true);
    setFieldError(field, `${field} is required`);
    setError('Missing required field');
  }, [setFieldError, setFieldTouched]);

  const isValidStep = useCallback(async (step) => {
    switch(step) {
      case 2:
        if(!values.name) setMissingField('name');
        return !!(values.name && !errors.name);
      case 3:
        return !!(values.dmFeePercentage && !errors.dmFeePercentage && !errors.redirectUrl);
      default:
        if(!values.slug) setMissingField('slug');
        // const isValid = await handleUsernameValid();
        return !!(values.slug && !errors.slug);
    }
  }, [values, errors, setMissingField])

  const handleNextStep = async () => {
    // if(usernameLoading) return;
    if(await isValidStep(step)) {
      sendPlatformEvent({
        action: `onboarding step ${ step || 1 } complete`,
      })
      setStep((step || 1) + 1);
    }
  }

  const handleStripeOnboarding = () => {
    navigate('/platform/stripe/onboarding')
  }

  const onFormSubmit = useCallback(async (event) => {
    event.preventDefault();
    if(isSubmitting) return;
    setSubmitting(true);
    if(isValidStep(step)) {
      await handleSubmit(values);
    } else {
      setError('Missing required field');
      setSubmitting(false);
    }
  }, [handleSubmit, isValidStep, setSubmitting, setError, step, values, isSubmitting])

  return (
    <div className='creator-signup'>
      <Stack direction='row' alignItems='center' sx={{ m: '30px' }}>
        { step > 1 && step !== 4 && <IconButton 
          onClick={() => step > 1 && setStep(step - 1)} 
          sx={{ mb: '6px' }}
        >
          <BackArrow />
        </IconButton> }
        <BeepLogo />
        <Box sx={{ flexGrow: 2 }} />
        <div className='creator-signup-progress'>
            <div
              className={ 'creator-signup-step' }
              style={{ width: `${(100 / 4) * Number(step || 1) }%` }}
              onClick={() => step > 1 && setStep(step - 1)}
            />
        </div>
      </Stack>
      <h3 className='creator-signup-title'>{ getStepHeader(step) }</h3>
      <p className='creator-signup-subtitle'>{ getStepSubtitle(step) }</p>
      <div className='creator-signup-form'>
        <div className='creator-signup-form-content'>
          { step === 1 && (
            <CustomInput 
              name='slug' 
              label='CHOOSE A HANDLE' 
              autoComplete='off'
              className='username'
              valid={ !errors.slug }
              // onBlur={ handleUsernameValid }
              // helperText={ usernameLoading 
              //   ? 'Checking availability...' 
              //   : 'Can only contain letters and numbers' 
              // }
              // endAdornment={
              //   validUsername && !errors.username 
              //     ? <CheckCircleOutlineIcon color='success' /> 
              //     : null
              // }
              placeholder='platform-handle'
            />
          )}
          { (!step || step === 2) && (
            <>
              <div className='name-group'>  
                <CustomInput 
                  name='name' 
                  label='NAME' 
                  className='name-group-item'
                  placeholder='Platform Name'
                  fullWidth={ true }
                />
              </div>
            </>
          )}
          { step === 3 && (
            <>
              <div className='dmFeePercentage'>
                <CustomInput 
                  name='dmFeePercentage'
                  className='dmPriceInputs'
                  type='number'
                  endAdornment='%'
                  placeholder={20}
                  inputProps={{ min: 12 }}
                />
                <p className='settings-small-text'>This is the percentage you take from creators after Beep and Stripe fees.</p>
                <Stack direction='row' justifyContent='space-between'>
                  <Typography sx={{ color: '#8585A3' }}>Beep fee</Typography>
                  <Typography sx={{ color: '#8585A3' }}>(8%)</Typography>
                </Stack>
                <Stack direction='row' justifyContent='space-between'>
                  <Typography sx={{ color: '#8585A3' }}>Stripe fee</Typography>
                  <Typography sx={{ color: '#8585A3' }}>(2.9%)</Typography>
                </Stack>
                <Stack direction='row' justifyContent='space-between'>
                  <Typography><b>Your commission</b></Typography>
                  { values.dmFeePercentage && <Typography><b>
                    {values.dmFeePercentage - 10.9}%
                  </b></Typography> }
                </Stack>
                <Stack direction='row' justifyContent='space-between'>
                  <Typography><b>Creator's take</b></Typography>
                  { values.dmFeePercentage && <Typography><b>
                    {100 - values.dmFeePercentage}%
                  </b></Typography> }
                </Stack>
              </div>
              <CustomInput 
                name='redirectUrl' 
                className='dmPriceInputs'
                label='Redirect Url (Optional)'
                fullWidth={ true }
              />
              <p className='settings-small-text'>This is the url on your platform that creators should be redirected to after signup. We will append the newly created user's Beep handle to the url.</p>
            </>
          )}
        </div>
        <div className='creator-signup-submit'>
          <Snackbar 
            className='error'
            open={ !!error } 
            autoHideDuration={ 3000 } 
            onClose={ () => setError() }
          >
            <Alert 
              className='error-alert'
              onClose={ () => setError() } 
              severity="error" 
              variant="filled"
            >
              { error } 
            </Alert>
          </Snackbar>
          { step === 3 
            ? <div>
              <CustomButton
                type='submit'
                onSubmit={ onFormSubmit }
                loading={ loading }
                sx={{ mb: 2 }}
              >
                Confirm
              </CustomButton>
            </div>
            : <>
              <CustomButton
                type='button'
                onSubmit={ step === 4 ? handleStripeOnboarding : handleNextStep }
              >
                { step === 4 ? 'Continue to stripe' : 'Next' }
              </CustomButton>
            </>
          }
        </div>
        { step === 1 && <p className='agreeText'>By clicking Next, you agree to Beep’s <Link to='/terms'>Terms and Conditions</Link> and confirm you have read our <Link to='/privacy'>Privacy Notice</Link>. You may receive offers, news, and updates from us.</p> }
      </div>
    </div>
  )
}

PlatformSignupForm.propTypes = {
  handleSubmit: PropTypes.func,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  setFieldError: PropTypes.func,
  setSubmitting: PropTypes.func,
  error: PropTypes.string,
  errors: PropTypes.string,
  touched: PropTypes.func,
  loading: PropTypes.bool
}

export default withFormik({
  mapPropsToValues: () => ({
    ...INITIAL_VALUES,
    username: window.localStorage.getItem('username')
  }),
  validationSchema,
  handleSubmit: (values, { setSubmitting, props: { onSubmit } }) => {
    return onSubmit(values)
      .then(() => setSubmitting(false));
  },
  displayName: 'CreatorPlatformSignupForm'
})(PlatformSignupForm);