import React, { useContext } from "react";
import { Box, Skeleton } from "@mui/material";
import PropTypes from 'prop-types';

import './index.css';
import ChatPreview from './ChatPreview';
import { UserContext } from "../../context";

const FanChatList = ({ 
  conversations, 
  loading,
  refetchConversations,
  onError,
  className,
}) => {
  const { user } = useContext(UserContext);

  return (
    <div className={ `chat-list ${ className }` }>
      { loading && (
        <>
          <Skeleton variant="rectangular" className='chat-preview-loading' height={91} />
          <Skeleton variant="rectangular" className='chat-preview-loading' height={91} />
          <Skeleton variant="rectangular" className='chat-preview-loading' height={91} />
          <Skeleton variant="rectangular" className='chat-preview-loading' height={91} />
        </>
      )}
      { !loading && (
        <Box sx={{ mt: 3 }}>
          <h5>Recent</h5>
          { conversations.needsReply.map(chat => (
            <ChatPreview 
              key={ chat.chatId }
              chat={ chat }
              user={ user }
              refetchConversations={ refetchConversations }
              needsReply={ true }
              recipient={ chat.recipient }
              onError={ onError }
            />
            )) }
            { conversations.replied?.map(chat => (
              <ChatPreview 
                key={ chat.chatId }
                chat={ chat }
                user={ user }
                refetchConversations={ refetchConversations }
                recipient={ chat.recipient }
                needsReply={ false }
              />
            )) }
        </Box>
      )}
    </div>
  )
}

FanChatList.propTypes = {
  onError: PropTypes.func,
  conversations: PropTypes.shape({
    needsReply: PropTypes.arrayOf(PropTypes.shape({
      chatId: PropTypes.string,
      recipient: PropTypes.shape({
        id: PropTypes.string
      })
    })),
    replied: PropTypes.arrayOf(PropTypes.shape({
      chatId: PropTypes.string,
      recipient: PropTypes.shape({
        id: PropTypes.string
      })
    })),
  }), 
  loading: PropTypes.bool,
  refetch: PropTypes.func,
}

export default FanChatList;