import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Avatar, Badge } from '@mui/material';
import cns from 'classnames';

import styles from './AvatarUpload.module.css';
import camera from '../../img/camera.png';

const AvatarUpload = ({ onSuccessfulUpload, onError, size = 'regular', url, className }) => {
  const [imagePreview, setImagePreview] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = React.createRef();

  const openFileDialog = () => {
    if(isLoading) return;
    fileInputRef.current.click();
  }

  const handleAvatarUpload = useCallback((evt) => {
    setIsLoading(true);
    const data = new FormData();
    data.append('file', evt.target.files[0]);
    data.append('upload_preset', 'avatar');
    data.append('cloud_name','creator-tools');
    axios('https://api.cloudinary.com/v1_1/creator-tools/image/upload',{
      method: 'post',
      data
    }).then(res => {
      setImagePreview(res.data.secure_url);
      onSuccessfulUpload(res.data.secure_url);
      setIsLoading(false);
    }).catch(() => {
      onError('Unable to upload image. Please try again.');
      setIsLoading(false);
    })
  }, [onError, onSuccessfulUpload])

  return (
    <div className={cns(className, styles.upload)} onClick={openFileDialog}>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <div className={styles.cameraContainer}>
            <img 
              alt="camera icon" 
              src={camera} 
              className={ size === 'small' ? styles.smallCamera : styles.camera} 
            />
          </div>
        }
      >
        <Avatar
          alt='User Avatar'
          name='avatarUrl'
          className={ size === 'small' ? styles.smallAvatar : styles.avatar }
          src={ imagePreview || url }
        />
      </Badge>
      <input 
        type='file' 
        id='avatarUpload' 
        onChange={ handleAvatarUpload }
        className={styles.uploadInput}
        ref={fileInputRef}
      /> 
    </div>
  )
}

AvatarUpload.propTypes = {
  onSuccessfulUpload: PropTypes.func,
  onError: PropTypes.func,
  small: PropTypes.bool
}

export default AvatarUpload;