import React, { useCallback, useContext, useState } from "react";
import BackButton from "../../../components/BackButton";
import { Alert, Input, Snackbar } from "@mui/material";

import camera from '../../../img/camera-gradient.png';
import video from '../../../img/video-gradient.png';
import styles from './CreateWelcome.module.css';
import CustomButton from "../../../components/CustomButton";
import Div100vh from "../../../components/Div100vh";
import { UserContext } from "../../../context";
import CloudinaryUpload from "../../../components/CloudinaryUpload";
import useMutate from "../../../hooks/useMutate";
import { useNavigate } from "react-router-dom";

const CreateWelcome = () => {
  const { user, refreshUser } = useContext(UserContext);
  const [input, setInput] = useState(user?.creator?.welcome?.text);
  const [preview, setPreview] = useState(user?.creator?.welcome?.image || user?.creator?.welcome?.video);
  const [type, setType] = useState(user?.creator?.welcome?.image ? 'image' : 'video');
  const [error, setError] = useState();
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  const [updateCreator, { loading }] = useMutate(`/creator/${ user?.creator?.id }`, { method: 'PUT' });

  const handleChange = (e) => setInput(e.target.value);
  const handleSuccessfulUpload = (type) => (url) => {
    setType(type);
    setPreview(url);
  }
  const handleSubmit = useCallback(async () => {
    if(loading) return;
    const values = {
      welcomeMessageAck: true,
      welcomeText: input,
    }
    if(type === 'video') values.welcomeVideo = preview;
    if(type === 'image') values.welcomeImage = preview;
    const { error } = await updateCreator(values);
    if(error) {
      setError(error)
    } else {
      await refreshUser();
      navigate(-1);
    }
  }, [input, loading, preview, type, updateCreator, setError, navigate, refreshUser]);

  return (
    <Div100vh className={ styles.container }>
      <Snackbar 
        open={ !!error } 
        autoHideDuration={ 3000 } 
        onClose={ () => setError() }
      >
        <Alert 
          className='error-alert'
          onClose={ () => setError() } 
          severity="error" 
          variant="filled"
        >
          { error }
        </Alert>
      </Snackbar>
      <div className={ styles.header }>
        <BackButton />
        <h3 className={ styles.title }>Welcome Message</h3>
        <div className={ styles.placeholder } />
      </div>
      <div className={ styles.form}>
        <p className={ styles.desc }>
          Fans will see this message when they first enter your chat thread.
        </p>
        <Input
          value={ input }
          className={ styles.input }
          disableUnderline
          placeholder='Type welcome message here'
          multiline
          fullWidth
          onChange={ handleChange }
        />
        <div className={ styles.cta }>
          <CloudinaryUpload
            type='image'
            onSuccessfulUpload={ handleSuccessfulUpload('image') }
            loading={uploading}
            onLoading={setUploading}
          >
            <div className={ styles.btn }>
              <img src={ camera } alt='Upload' className={ styles.camera } />
              <p>Include a photo</p>
            </div>
          </CloudinaryUpload>
          <CloudinaryUpload
            type='video'
            onSuccessfulUpload={ handleSuccessfulUpload('video') }
            loading={uploading}
            onLoading={setUploading}
          >
            <div className={ styles.btn }>
              <img src={ video } alt='Upload' className={ styles.video } />
              <p>Include a video</p>
            </div>
          </CloudinaryUpload>
        </div>
      </div>
      <div className={ styles.preview }>
        { preview && type === 'video' && (
          <video className={ styles.videoPreview } controls src={ preview } alt='video preview' /> 
        )}
        { preview && type === 'image' && (
          <img className={ styles.imagePreview } src={ preview } alt='Preview' /> 
        )}
      </div>
      <div className={ styles.submit }>
        <CustomButton 
          onSubmit={ handleSubmit }
          disabled={ !input && !preview }
          loading={ loading || uploading }
        >Save changes</CustomButton>
      </div>
    </Div100vh>
  )
}

export default CreateWelcome;