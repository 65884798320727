import { useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

import config from '../conf';

const useLazyFetch = (path, { auth = true } = {}) => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const fetch = async () => {
    setLoading(true);
    return axios({
      method: 'get',
      url: `${ config.API_ENDPOINT }${ path }`,
      headers: auth ? {
        Authorization: `Bearer ${ await getAccessTokenSilently() }`
      } : {}
    })
      .then(res => {
        setData(res.data);
        setLoading(false);
        return res;
      })
      .catch((err) => {
        setError(err.response?.data?.message);
        setLoading(false);
        return { error: err.response?.data?.message }
      })
  };

  return [fetch, { loading, error, data }];
}

export default useLazyFetch;