import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Alert, Checkbox, FormControlLabel, IconButton, Snackbar } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import CustomButton from '../../../../components/CustomButton';
import CustomInput from '../../../../components/CustomInput';
import MuiPhoneNumber from 'material-ui-phone-number';
import './index.css';
import { useCreatorAnalytics } from '../../../../hooks/useAnalytics';
import AvatarUpload from '../../../../components/AvatarUpload';
import useLazyFetch from '../../../../hooks/useLazyFetch';
import { ReactComponent as BeepLogo } from '../../../../img/logo-with-text.svg';
import { ReactComponent as BackArrow } from '../../../../img/back-arrow.svg';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../context';
import { Box, Stack } from '@mui/system';
import stripeLogo from '../../../../img/stripe.png';

const INITIAL_VALUES = {
  phoneNotifications: true,
  termsAndConditions: false,
  firstMessageFree: false,
  dmPrice: 10
}

const usernameRules = /^(?=.{3})[A-Za-z\d_-]+$/ig;
const phoneRules = /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/;

const validationSchema = Yup.object().shape({
  username: Yup
    .string()
    .min(3, 'Must be at least 3 letters or numbers')
    .max(25, 'Must be 25 characters or less')
    .matches(usernameRules, {message: 'Your username must contain only letters or numbers'})
    .required('Username is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phoneNumber: Yup.string()
    .matches(phoneRules, {message: 'This is not a valid phone number'})
    .required('Phone number is required'),
  dmPrice: Yup.number().positive('Your DM price must be a positive number').required('DM price is required'),
})

const getStepHeader = (step) => {
  switch(step) {
    case 2:
      return 'Tell us about yourself';
    case 3:
      return 'Set DM price'
    case 4:
        return 'Add profile pic'
    case 5:
        return <img src={ stripeLogo } alt='Earn with Stripe' width={ 115 } />;
    default: return 'Choose a username';
  }
}

const getStepSubtitle = (step) => {
  switch(step) {
    case 3:
      return 'This is the amount your fans pay to send you a direct message. You can change this later.';
    case 5:
      return 'Almost done! To start earning, create a Stripe account.'
    default:
      return;
  }
}

const CreatorSignupForm = ({ 
  values,
  touched,
  errors,
  setFieldValue,
  setFieldError,
  setSubmitting,
  handleSubmit,
  loading,
  setFieldTouched,
  isSubmitting
}) => {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [step, setStep] = useState(1);
  const [validUsername, setValidUsername] = useState(false);
  const [isUsernameAvailable, { loading: usernameLoading }] = useLazyFetch(`/username/available/${ values.username }`, { auth: false });
  const sendCreatorEvent = useCreatorAnalytics();
  const { user } = useContext(UserContext);

  const handleUsernameValid = useCallback(async () => {
    setFieldTouched('username', true);
    if(!values.username || errors?.username) return;
    const { data } = await isUsernameAvailable();
    if(data?.available) {
      setValidUsername(true);
      setFieldError('username', '');
      return true;
    } else {
      setValidUsername(false);
      setFieldError('username', 'Username is not available');
      return false;
    }
  }, [isUsernameAvailable, setFieldError, values.username, setFieldTouched, errors]);

  const handleUsernameChange = (e) => {
    setFieldValue('username', e.target.value?.toLowerCase());
  };

  // if onboarding is complete, redirect to home
  useEffect(() => {
    if(user?.onboardingComplete && user?.isCreator) {
      if(user?.creator?.isStripeSetup) {
        navigate('/creator/home');
      } else {
        setStep(5);
      }
    }
  }, [user, navigate])

  const setMissingField = useCallback((field) => {
    setFieldTouched(field, true);
    setFieldError(field, `${field} is required`);
    setError('Missing required field');
  }, [setFieldError, setFieldTouched]);

  const isValidStep = useCallback(async (step) => {
    switch(step) {
      case 2:
        if(!values.firstName) setMissingField('firstName');
        if(!values.lastName) setMissingField('lastName');
        if(!values.phoneNumber) setMissingField('phoneNumber');
        return !!(values.firstName && values.lastName && values.phoneNumber && !errors.firstName && !errors.lastName && !errors.phoneNumber);
      case 3:
        return !!(values.dmPrice && !errors.dmPrice);
      default:
        if(!values.username) setMissingField('username');
        const isValid = await handleUsernameValid();
        return !!(values.username && !errors.username && isValid);
    }
  }, [values, errors, setMissingField, handleUsernameValid])

  const handleSuccessfulUpload = useCallback((url) => {
    setFieldValue('avatarUrl', url)
  }, [setFieldValue])

  const handlePhoneInput = useCallback((value) => {
    let phoneNumber = value.replace(/-|\s|([()])/g,"");
    setFieldTouched('phoneNumber', true);
    setFieldValue('phoneNumber', phoneNumber);
  }, [setFieldValue, setFieldTouched])

  const handlePhoneNotifications = useCallback((event) => {
    setFieldValue('phoneNotifications', event.target.checked);
  }, [setFieldValue])

  const handleFirstMessageFree = useCallback((event) => {
    setFieldValue('firstMessageFree', event.target.checked);
  }, [setFieldValue])

  const handleNextStep = async () => {
    if(usernameLoading) return;
    if(await isValidStep(step)) {
      sendCreatorEvent({
        action: `onboarding step ${ step || 1 } complete`,
      })
      setStep((step || 1) + 1);
    }
  }

  const handleStripeOnboarding = () => {
    navigate('/creator/stripe/onboarding')
  }

  const onFormSubmit = useCallback(async (event) => {
    event.preventDefault();
    if(isSubmitting) return;
    setSubmitting(true);
    if(isValidStep(step)) {
      await handleSubmit(values);
    } else {
      setError('Missing required field');
      setSubmitting(false);
    }
  }, [handleSubmit, isValidStep, setSubmitting, setError, step, values, isSubmitting])

  return (
    <div className='creator-signup'>
      <Stack direction='row' alignItems='center' sx={{ m: '30px' }}>
        { step > 1 && step !== 5 && <IconButton 
          onClick={() => step > 1 && setStep(step - 1)} 
          sx={{ mb: '6px' }}
        >
          <BackArrow />
        </IconButton> }
        <BeepLogo />
        <Box sx={{ flexGrow: 2 }} />
        <div className='creator-signup-progress'>
            <div
              className={ 'creator-signup-step' }
              style={{ width: `${(100 / 5) * Number(step || 1) }%` }}
              onClick={() => step > 1 && setStep(step - 1)}
            />
        </div>
      </Stack>
      <h3 className='creator-signup-title'>{ getStepHeader(step) }</h3>
      <p className='creator-signup-subtitle'>{ getStepSubtitle(step) }</p>
      <div className='creator-signup-form'>
        <div className='creator-signup-form-content'>
          { step === 1 && (
            <CustomInput 
              name='username' 
              label='CHOOSE A USERNAME' 
              autoComplete='off'
              className='username'
              valid={ !errors.username && validUsername }
              onBlur={ handleUsernameValid }
              helperText={ usernameLoading 
                ? 'Checking availability...' 
                : 'Can only contain letters and numbers' 
              }
              onChange={ handleUsernameChange }
              startAdornment={
                <p className='username-placeholder'>beepmehere.com/</p>
              }
              endAdornment={
                validUsername && !errors.username 
                  ? <CheckCircleOutlineIcon color='success' /> 
                  : null
              }
              placeholder='yourname'
            />
          )}
          { (!step || step === 2) && (
            <>
              <div className='name-group'>  
                <CustomInput 
                  name='firstName' 
                  label='FIRST NAME' 
                  className='name-group-item'
                  fullWidth={ true }
                />
                <CustomInput 
                  name='lastName' 
                  label='LAST NAME'
                  className='name-group-item'
                  fullWidth={ true }
                />
              </div>
                <MuiPhoneNumber
                  onChange = { handlePhoneInput }
                  name='phoneNumber'
                  label='ENTER PHONE NUMBER'
                  defaultCountry="us"
                  fullWidth
                />
                {errors.phoneNumber && touched.phoneNumber && <p className='error-message'>{errors.phoneNumber}</p>}
                <FormControlLabel
                  className='creator-signup-input'
                  control={<Checkbox 
                    checked={ values.phoneNotifications }
                    name='phoneNotifications'
                    onChange={ handlePhoneNotifications }
                  />} 
                  label='Get notified via SMS text when fans respond' 
                  classes={{
                    label: 'checkbox-label'
                  }}
                />
            </>
          )}
          { step === 3 && (
            <div className='dmPrice'>
              <CustomInput 
                name='dmPrice'
                type='number'
                startAdornment='$'
                inputProps={{ min: 5 }}
              />
              <FormControlLabel
                className='creator-signup-input'
                control={<Checkbox 
                  checked={ values.firstMessageFree }
                  name='firstMessageFree'
                  onChange={ handleFirstMessageFree }
                />} 
                label='Make the first message free for my fans (you can change this later in settings)' 
                classes={{
                  label: 'checkbox-label'
                }}
              />
            </div>
          )}
          { step === 4 && (
            <AvatarUpload 
              onSuccessfulUpload={ handleSuccessfulUpload } 
              onError={ setError } 
              className='creatorSignupAvatar'
            />
          )}
        </div>
        <div className='creator-signup-submit'>
          <Snackbar 
            className='error'
            open={ !!error } 
            autoHideDuration={ 3000 } 
            onClose={ () => setError() }
          >
            <Alert 
              className='error-alert'
              onClose={ () => setError() } 
              severity="error" 
              variant="filled"
            >
              { error } 
            </Alert>
          </Snackbar>
          { step === 4 
            ? <div>
              <CustomButton
                type='submit'
                onSubmit={ onFormSubmit }
                loading={ loading }
                sx={{ mb: 2 }}
              >
                Confirm
              </CustomButton>
              <CustomButton
                type='button'
                onSubmit={ onFormSubmit }
                loading={ loading }
                className='skip-button'
              >
                Skip for now
              </CustomButton>
            </div>
            : <>
              <CustomButton
                type='button'
                onSubmit={ step === 5 ? handleStripeOnboarding : handleNextStep }
              >
                { step === 5 ? 'Continue to stripe' : 'Next' }
              </CustomButton>
            </>
          }
        </div>
        { step === 1 && <p className='agreeText'>By clicking Next, you agree to Beep’s <Link to='/terms'>Terms and Conditions</Link> and confirm you have read our <Link to='/privacy'>Privacy Notice</Link>. You may receive offers, news, and updates from us.</p> }
      </div>
    </div>
  )
}

CreatorSignupForm.propTypes = {
  handleSubmit: PropTypes.func,
  values: PropTypes.any,
  setFieldValue: PropTypes.func,
  setFieldError: PropTypes.func,
  setSubmitting: PropTypes.func,
  error: PropTypes.string,
  errors: PropTypes.string,
  touched: PropTypes.func,
  loading: PropTypes.bool
}

export default withFormik({
  mapPropsToValues: () => ({
    ...INITIAL_VALUES,
    username: window.localStorage.getItem('username')
  }),
  validationSchema,
  handleSubmit: (values, { setSubmitting, props: { onSubmit } }) => {
    return onSubmit(values)
      .then(() => setSubmitting(false));
  },
  displayName: 'CreatorSignupForm'
})(CreatorSignupForm);